import { css } from "@emotion/react";
import { SelectLeagueDropDown } from "../../components";
import {
  MANAGER_TRANSFERS_PATH,
  VIEW_TEAM_PATH,
} from "../../utils/regexExpressions";
import { ROUTES } from "../../config/routes";
import { Outlet, useLocation } from "react-router-dom";

export const SelectLeagueLayout = () => {
  const { pathname } = useLocation();

  const showSelectLeagueDropDown = !(
    [ROUTES.PRIVACY_POLICY_PAGE, ROUTES.TERMS_OF_SERVES_PAGE].includes(
      pathname as any
    ) ||
    VIEW_TEAM_PATH.test(pathname) ||
    MANAGER_TRANSFERS_PATH.test(pathname)
  );
  return (
    <>
      {showSelectLeagueDropDown && (
        <div css={selectLeagueContainer}>
          <SelectLeagueDropDown />
        </div>
      )}
      <Outlet />
    </>
  );
};

const selectLeagueContainer = css`
  margin-bottom: 1rem;
`;
