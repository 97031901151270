import { Button } from "../../../components";
import { useState } from "react";
import { css } from "@emotion/react";
import { animatePulse } from "../../../config/styles";
import { SESSION_STORAGE } from "../../../config/app.config";
import { useLocation } from "react-router-dom";
import {
  GA4_EVENTS,
  TrackGoogleAnalyticsEvent,
} from "../../../utils/google-analytics";
import { useUserContext } from "../../../context/UserContext";
import { signInUserWithProvider } from "../utils/authHelpers";
import { Provider } from "@supabase/supabase-js";
import { useGlobalInfoContext } from "../../../context/GlobalInfoContext";

interface IOAuthButton {
  provider: Provider;
  children: React.ReactNode | string;
}

/** OAuth button that signing in the user with oAuth and redirect to the team id page */
export const OAuthButton = ({ provider, children }: IOAuthButton) => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { user, signOutUser } = useUserContext();
  const { setLeagues } = useGlobalInfoContext();

  const handleOAuthButton = async () => {
    TrackGoogleAnalyticsEvent(GA4_EVENTS.SIGN_UP_GOOGLE_CLICKED);
    setIsLoading(true);

    if (location.state && location.state.from) {
      sessionStorage.setItem(
        SESSION_STORAGE.URL_HISTORY,
        location.state.from.pathname + location.state.from.search
      );
    }

    if (user && user.isAnonymous) {
      await signOutUser();
      setLeagues([]);
      sessionStorage.setItem(
        SESSION_STORAGE.IS_CONVERTED_ANON_USER_BY_OAuth,
        "true"
      );
    }

    // Sign up user
    await signInUserWithProvider(provider);

    setIsLoading(false);
  };

  return (
    <Button
      css={isLoading ? loadingStyle : undefined}
      onClick={handleOAuthButton}
      button="google"
      disabled={isLoading}
    >
      {children}
    </Button>
  );
};

const loadingStyle = css`
  ${animatePulse}
  background-color: white  !important;
`;
