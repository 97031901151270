import { css } from "@emotion/react";
import { AppTooltip } from "../AppTooltip";
import { EPL_PLAYER_SUMMARY } from "../../constants/view-team-pages.constants";
import { Stack } from "@mui/material";
import { stackCenteredStyleMuiDataGrid } from "../../config/styles";

export const TBCTableCellEPLPlayerSummaryFixtures = () => {
  return (
    <Stack css={stackCenteredStyleMuiDataGrid}>
      <AppTooltip subTitle={EPL_PLAYER_SUMMARY.TBC.TOOL_TIP}>
        <p
          css={css`
            text-decoration: underline;
          `}
        >
          {EPL_PLAYER_SUMMARY.TBC.TITLE}
        </p>
      </AppTooltip>
    </Stack>
  );
};
