import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { redColor, tableHeaderBgColor } from "../../../config/theme";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  EPL_PLAYER_SUMMARY,
  tabsConstants,
} from "../../../constants/view-team-pages.constants";
import { AppTabsBar, Button } from "../../../components";
import { ITeamInfo, useGetTeamInfo } from "../../../utils/hooks/useGetTeamInfo";
import {
  EplPlayerFixtureSummary,
  EplPlayerSummaryFixtureTable,
  EplPlayerOwnership,
  EplPlayerSummaryHistoryTable,
  EplPlayerSummaryOwnershipTable,
  EplPlayerSummaryStats,
  EplStatsTable,
} from ".";
import { useState } from "react";
import { useEplPlayerSummary } from "../../../utils/hooks/useEplPlayerSummary";
import { useParams } from "react-router-dom";
import { PATH_PARAMS } from "../../../config/routes";

interface IEplPlayerSummary {
  open: boolean;
  onClose(): void;
  eplPlayer: ITeamInfo["picks"][0] | null;
}

/** Renders a modal that display the player stats, fixture and Ownership in league */
export const EplPlayerSummary = (props: IEplPlayerSummary) => {
  const urlParams = useParams();
  const { teamInfo } = useGetTeamInfo({
    teamId: urlParams[PATH_PARAMS.TEAM_ID]!,
  });
  const [isShowMore, setIsShowMore] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    EPL_PLAYER_SUMMARY.TABS[0].value
  );
  const { eplPlayersSummary } = useEplPlayerSummary();

  const stats = teamInfo?.picks
    .find((i) => i.element === props.eplPlayer?.element)!
    .explain.at(-1);

  const selectedEplPlayerSummary = eplPlayersSummary?.find(
    (i) => i.element === props.eplPlayer?.element
  );

  const handleShowMoreBtnClick = () => {
    setIsShowMore(true);
  };

  const handleTabsChange = (_: any, value: string) => {
    setSelectedTab(value);
  };

  return (
    <Modal css={modalContainer} open={props.open} onClose={props.onClose}>
      <Stack css={rootStyles}>
        {/* Header */}
        <Stack css={headerSectionStyles} paddingInline={"1rem"}>
          <Typography variant="h1">{EPL_PLAYER_SUMMARY.TITLE}</Typography>
          <IconButton onClick={props.onClose}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>

        <span css={dividerStyles} />

        <Box paddingInline={"1rem"}>
          <EplPlayerOwnership eplPlayer={props.eplPlayer} />
        </Box>

        {!isShowMore ? (
          <Box paddingInline={"1rem"}>
            {stats ? (
              <>
                <EplPlayerFixtureSummary fixtureId={stats?.fixture} />
                <EplStatsTable stats={stats?.stats} />
              </>
            ) : (
              <NoStats />
            )}
            <Button
              button="gradient"
              css={btnStyles}
              onClick={handleShowMoreBtnClick}
            >
              {EPL_PLAYER_SUMMARY.BTN}
            </Button>
          </Box>
        ) : (
          <>
            <EplPlayerSummaryStats info={selectedEplPlayerSummary?.info} />

            <Box css={tabBarContainerStyles}>
              <AppTabsBar
                tabs={EPL_PLAYER_SUMMARY.TABS}
                value={selectedTab}
                onTabsChange={handleTabsChange}
              />
            </Box>

            <Box css={boxStyles}>
              {selectedTab === tabsConstants.history && (
                <EplPlayerSummaryHistoryTable
                  historyRows={selectedEplPlayerSummary?.summary.history}
                  historyPastRows={
                    selectedEplPlayerSummary?.summary.history_past
                  }
                />
              )}

              {selectedTab === tabsConstants.fixture && (
                <EplPlayerSummaryFixtureTable
                  fixtureRows={selectedEplPlayerSummary?.summary.fixtures}
                />
              )}

              {selectedTab === tabsConstants.ownership &&
                selectedEplPlayerSummary && (
                  <EplPlayerSummaryOwnershipTable
                    eplPlayerSummary={selectedEplPlayerSummary}
                  />
                )}
            </Box>
          </>
        )}
      </Stack>
    </Modal>
  );
};

const NoStats = () => {
  return (
    <Stack css={noStatsRootStyles}>
      <CloseRoundedIcon />
      <Typography>{"No fixture this Game week"}</Typography>
    </Stack>
  );
};

const modalContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100vw;
  height: 100vh;
`;

const rootStyles = css`
  gap: 1rem;
  background-color: ${tableHeaderBgColor};
  width: 100%;
  max-width: 500px;
  border-radius: 1rem;
  padding-block: 1rem;
`;

const headerSectionStyles = css`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  h1 {
    font-size: clamp(1.5rem, 3vw, 2vw);
  }

  button {
    color: white;
  }
`;

const dividerStyles = css`
  background-color: #e1dfdf64;
  width: 100%;
  display: block;
  margin-inline: auto;
  height: 1px;
  border-radius: 999px;
`;

const btnStyles = css`
  font-size: clamp(1rem, 2vw, 1.3rem);
`;

const tabBarContainerStyles = css`
  box-shadow: 0 0 8px 0 #1b1b1b;
`;

const boxStyles = css`
  height: 300px;
  overflow: auto;
`;

const noStatsRootStyles = css`
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  svg {
    font-size: 80px;
    color: ${redColor};
  }

  p {
    font-size: clamp(1.1rem, 2vw, 1.3rem);
  }
`;
