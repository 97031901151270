import { css } from "@emotion/react";
import { tableHeaderBgColor } from "../../../config/theme";
import { Stack, Typography } from "@mui/material";
import { mq } from "../../../config/styles";
import logo from "../../../assets/identity/logo_name.svg";
import XLogo from "../../../assets/icons/x.svg";
import redditLogo from "../../../assets/icons/reddit.svg";
import { Link } from "react-router-dom";
import { SOCIAL_MEDIA_LINKS } from "../../../constants/global.constants";
import { LANDING_PAGE_CONTENT } from "../../../constants/landing-page.constants";

/**
 * The footer component for the landing page.
 *
 * It contains the logo, social media links, copywrite information, and
 * quick links.
 */
export const LandingPageFooter = () => {
  return (
    <Stack css={rootStyles}>
      <Stack css={firstSectionContainerStyles}>
        <img src={logo} />
      </Stack>

      <hr css={dividerStyles} />

      <Stack css={lastSectionContainerStyles}>
        <Stack css={socialMediaAndCopyWriteStyles}>
          <Stack css={socialMediaContainerStyles}>
            <Link to={SOCIAL_MEDIA_LINKS.TWITTER}>
              <img src={XLogo} />
            </Link>
            <Link to={SOCIAL_MEDIA_LINKS.REDDIT}>
              <img src={redditLogo} />
            </Link>
            <Typography>{LANDING_PAGE_CONTENT.FOOTER.FOLLOW_US}</Typography>
          </Stack>

          <Typography css={copyWriteStyles}>
            {LANDING_PAGE_CONTENT.FOOTER.COPY_RIGHT}
          </Typography>
        </Stack>

        <Stack component={"ul"} css={quickLinksStyles}>
          {LANDING_PAGE_CONTENT.FOOTER.QUICK_LINKS.map((link) => (
            <li key={link.title}>
              <a href={link.link}>{link.title}</a>
            </li>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

const rootStyles = css`
  background-color: ${tableHeaderBgColor};
  padding: 1rem;
  border-radius: 1rem;
  gap: 1rem;

  ${mq["md"]} {
    padding: 2rem;
  }
`;

const firstSectionContainerStyles = css`
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  ${mq["md"]} {
    flex-direction: row;
  }
`;

const dividerStyles = css`
  width: 100%;
  border: 1px solid #c1c7cd;
  height: 1px;
  margin-block: 0 1rem;
`;

const lastSectionContainerStyles = css`
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  ${mq["lg"]} {
    flex-direction: row;
    align-items: end;
  }
`;

const quickLinksStyles = css`
  font-size: clamp(0.875rem, 2vw, 1rem);
  gap: 1rem;
  width: 100%;

  font-weight: 600;
  ${mq["md"]} {
    width: auto;
    align-items: end;
    flex-direction: row;
  }
`;

const socialMediaAndCopyWriteStyles = css`
  flex: 1;
  width: 100%;
`;

const socialMediaContainerStyles = css`
  width: fit-content;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-inline: auto;
  min-width: fit-content;
  margin-bottom: 1rem;
  p {
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
    ${mq["md"]} {
      font-size: 1.25rem;
    }
  }

  img {
    width: 1.5rem;
  }

  ${mq["md"]} {
    margin-inline: unset;
  }
`;

const copyWriteStyles = css`
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;

  ${mq["md"]} {
    text-align: left;
  }
`;
