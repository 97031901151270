import { Outlet, useLocation } from "react-router-dom";
import {
  MainAppNavigationMenu,
  TopNavigationBar,
  Footer,
  FloatingSignUp,
} from "../../components";
import { css } from "@emotion/react";
import { container, mq } from "../../config/styles";
import { SideNavigationMenu } from "../../components/navigations/SideNavigationMenu";
import { Stack } from "@mui/material";
import { mainNavigationHeight, navbarHeight } from "../../config/theme";
import { useEffect, useRef, useState } from "react";
import { HIGHLIGHTS_PATH_NAME } from "../../utils/regexExpressions";
import { useIsSharedURL } from "../../utils/hooks/useIsSharedURL";
// import { useOfficialAuth } from "../../utils/hooks/useOfficialAuth";

/**
 * The main layout of the application render the top side and main navbars and the league DropDown menu
 */
export const MainLayout = () => {
  const { isShared } = useIsSharedURL();
  const { pathname } = useLocation();
  // useOfficialAuth();

  const outletContainerRef = useRef<HTMLDivElement>(null);

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const scrollTimeOut = setTimeout(() => {
      if (outletContainerRef.current) {
        if (HIGHLIGHTS_PATH_NAME.test(pathname)) {
          // wait for the page to render to full length before scroll
          setTimeout(() => {
            outletContainerRef.current?.scrollTo(0, scrollPosition);
          });
        } else {
          outletContainerRef.current.scrollTo(0, 0);
        }
      }
    });

    return () => {
      clearTimeout(scrollTimeOut);
    };
  }, [pathname]);

  const handleScroll = () => {
    if (outletContainerRef.current && HIGHLIGHTS_PATH_NAME.test(pathname)) {
      const { scrollTop } = outletContainerRef.current;
      setScrollPosition(scrollTop);
    }
  };

  return (
    <Stack css={mainLayout}>
      <div css={navBar}>
        <TopNavigationBar />
      </div>

      <Stack direction={"row"} css={mainContent}>
        <div css={sidebarStyle}>
          <SideNavigationMenu />
        </div>

        <div
          onScroll={handleScroll}
          ref={outletContainerRef}
          css={outletRootStyle(HIGHLIGHTS_PATH_NAME.test(pathname), isShared)}
        >
          <div css={outletContainerStyle}>
            <Outlet />
          </div>

          <Footer />
        </div>
      </Stack>

      <div css={mainNavigation}>
        <MainAppNavigationMenu />
      </div>

      {isShared && <FloatingSignUp />}
    </Stack>
  );
};

const mainLayout = css`
  height: 100vh;
  overflow: hidden;
`;

const mainContent = css`
  flex: 1;
`;

const outletRootStyle = (smooth: boolean, isShared: boolean) => css`
  ${container}
  height: calc(100vh - ${navbarHeight} - ${mainNavigationHeight});
  ${isShared &&
  css`
    height: calc(100vh - ${navbarHeight});
  `}
  width: 100%;
  overflow: auto;
  ${smooth && "scroll-behavior: smooth;"}

  ${mq["sm"]} {
    height: calc(100vh - ${navbarHeight});
  }
`;

const outletContainerStyle = css`
  min-height: calc(100vh - ${navbarHeight} - ${mainNavigationHeight});
  margin-bottom: 3rem;
`;

const navBar = css``;

const mainNavigation = css`
  height: ${mainNavigationHeight};
  width: 100vw;

  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;

  ${mq["sm"]} {
    display: none;
  }
`;

const sidebarStyle = css`
  /* Fixed side bar */
  display: none;
  height: calc(100vh - ${navbarHeight});

  ${mq["md"]} {
    min-width: 35%;
    display: block;
  }

  ${mq["lg"]} {
    min-width: 30%;
  }

  ${mq["xl"]} {
    min-width: 20%;
  }
`;
