import { Typography } from "@mui/material";
import { Button } from "../../components";
import { Helmet } from "react-helmet";
import { css } from "@emotion/react";
import waring from "../../assets/icons/warning.svg";
import { useGetUrl } from "../../utils/hooks/useGetUrl";
import { mainNavigationHeight, navbarHeight } from "../../config/theme";

const NOT_FOUND_PAGE_CONTENT = {
  META: {
    TITLE: "Page not found",
  },
  TITLE: "404 Page not found",
  SUBTITLE:
    "Looks like the page you're looking for doesn't exist. Go to the home page.",
  BTN: "Go to home",
};

export const NotFoundPage = () => {
  const { getHomePath } = useGetUrl();
  return (
    <div css={rootStyles}>
      <Helmet>
        <title>{NOT_FOUND_PAGE_CONTENT.META.TITLE}</title>
      </Helmet>
      <img src={waring} />

      <Typography variant="h1"> {NOT_FOUND_PAGE_CONTENT.TITLE}</Typography>
      <Typography variant="h2"> {NOT_FOUND_PAGE_CONTENT.SUBTITLE}</Typography>

      <Button button="gradient" to={getHomePath()}>
        {NOT_FOUND_PAGE_CONTENT.BTN}
      </Button>
    </div>
  );
};

const rootStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - ${navbarHeight} - ${mainNavigationHeight});
  gap: 1rem;
  max-width: 40rem;
  margin-inline: auto;

  h1 {
    font-size: clamp(2rem, 6vw, 3.5rem);
  }

  h2 {
    font-size: clamp(1rem, 3vw, 1.5rem);
    text-align: center;
  }
`;
