import { css } from "@emotion/react";
import { Stack } from "@mui/material";

interface ITotalPointsCellProps {
  total_points: number;
}

/** JSX for displaying total points in EPL Player Summary table. */
export const TotalPointsCell: React.FC<ITotalPointsCellProps> = ({
  total_points,
}) => <Stack css={pointsCellStyles}>{total_points}</Stack>;

const pointsCellStyles = css`
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: row;
  position: relative;
  ::after {
    content: "";
    width: 1px;
    height: 70%;
    background-color: var(--separator-color);
    position: absolute;
    right: -0.65rem;
  }
`;
