import userIcon from "../../../assets/icons/user.svg";
import {
  AutoCompleteInput,
  Button,
  ErrorMessage,
  ErrorModal,
  Input,
} from "../../../components";
import { Form, Formik } from "formik";
import {
  TeamDetailsType,
  TeamDetailsYupSchema,
} from "../../../utils/validation";
import { TEAM_DETAIL_PAGE } from "../../../constants/auth-pages.constants";
import { useUserContext } from "../../../context/UserContext";
import { ROUTES } from "../../../config/routes";
import { addTeamIdSupabase, validateTeamId } from "../utils/authHelpers";
import { AxiosError } from "axios";
import { handleErrors } from "../../../utils/handleErrors";
import { root } from "../styles";
import { useSnackbarContext } from "../../../context/SnackbarContext";
import { SNACKBAR_MESSAGES } from "../../../constants/global.constants";
import { Box, Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { supabaseUserHasTeamId } from "../../../utils/general.helpers";
import { SESSION_STORAGE } from "../../../config/app.config";
import { useNavigateState } from "../../../utils/hooks/useNavigateState";
import { IFplManager } from "../../../utils/types/types";
import { useEffect, useRef, useState } from "react";
import { axiosInstance } from "../../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../../config/fplChampEndpoints.constants";
import { secondaryColor } from "../../../config/theme";
import { Navigate } from "react-router-dom";
import { NavigateToHomePage } from "../../../utils/hooks/NavigateToHomePage";
import { useGlobalInfoContext } from "../../../context/GlobalInfoContext";
import { postTeamIdAnalytics } from "../../../utils/customAnalytics";

/**
 * A form to get the team id
 * Has an auto complete section to get get team id by team name
 * Has an input team for uer to enter the eam id directly
 */

export const TeamDetailsForm = () => {
  const initialValues = {
    team_id: "",
    generalErr: "",
  };

  const { user, setUser } = useUserContext();
  const { setLeagues } = useGlobalInfoContext();
  const { setSnackBarState } = useSnackbarContext();
  const navigate = useNavigateState();
  const redirectURL = sessionStorage.getItem(SESSION_STORAGE.URL_HISTORY);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (user) {
      postTeamIdAnalytics({ email: user.email });
    }
  }, [user]);

  const postManagerTeamId = async (teamID: TeamDetailsType) => {
    if (!user) {
      navigate(ROUTES.REGISTER_PAGE);
      setSnackBarState((prev) => ({
        ...prev,
        message: SNACKBAR_MESSAGES.REGISTER_FIRST_TEAM_DETAILS,
        isOpen: true,
      }));
    }

    const userHasTeamIdInSupabase = await supabaseUserHasTeamId();

    // if user doesn't have team id in supabase
    if (!userHasTeamIdInSupabase && !user?.isAnonymous) {
      await validateTeamId(teamID);
      const newUser = await addTeamIdSupabase(teamID);
      setUser(newUser);
    } else if (user) {
      sessionStorage.setItem(
        SESSION_STORAGE.ANON_USER_TEAM_ID,
        teamID.team_id.toString()
      );
      setUser({
        ...user,
        teamId: Number(teamID.team_id),
        selectedLeagueId: undefined, // reset select league -> defaults to first league in select menu
      });
      setLeagues([]);
    }
    setRedirect(true);
  };

  const handleSubmit = async (
    teamID: TeamDetailsType,
    { setErrors, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true);
      if (user) {
        postTeamIdAnalytics({
          email: user.email,
          teamId: String(teamID.team_id),
          inputType: "teamId",
        });
      }
      await postManagerTeamId(teamID);
      setSubmitting(false);
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response) {
          setErrors({ team_id: e.response.data.error.details.teamId });
        }
      } else {
        const err = handleErrors(e);
        setErrors({ generalErr: err.message });
      }
    }
  };

  /** Search By Team Name */

  const [managers, setManagers] = useState<IFplManager[] | null>([]);
  const [searchParam, setSearchParam] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fplManager = useRef<IFplManager | null>(null);

  const getManagers = async () => {
    if (user) {
      postTeamIdAnalytics({ email: user.email, searchTerm: searchParam });
    }
    const { data: managers } = await axiosInstance.get<IFplManager[]>(
      FPL_CHAMP_ENDPOINTS.GET_TEAM_ID_BY_TEAM_NAME(searchParam)
    );
    if (managers.length === 0) {
      setManagers(null);
    } else {
      setManagers(managers);
    }
  };

  const handleAutoCompleteChange = (inputValue: string) => {
    setManagers([]);
    setSearchParam(inputValue);
  };

  const handleItemClick = (item: IFplManager) => {
    setIsModalOpen(true);
    fplManager.current = item;
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
  };

  const handleConfirmClick = () => {
    if (fplManager.current) {
      if (user) {
        postTeamIdAnalytics({
          email: user.email,
          teamId: String(fplManager.current.entry),
          inputType: "teamName",
        });
      }

      postManagerTeamId({ team_id: fplManager.current.entry });
    }
    setIsModalOpen(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchParam) {
        getManagers();
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [searchParam]);

  if (redirect) {
    return redirectURL ? <Navigate to={redirectURL} /> : <NavigateToHomePage />;
  }

  return (
    <>
      <ErrorModal
        title={TEAM_DETAIL_PAGE.CONFIRM_TEAM.SUBTITLE}
        isOpen={isModalOpen}
        buttons={
          <Stack css={modalButtonStyle}>
            <Button button="outline" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button button="gradient" onClick={handleConfirmClick}>
              Confirm
            </Button>
          </Stack>
        }
      >
        {fplManager.current ? (
          <ul css={fplManagerDetailsStyles}>
            <li>
              <span>{TEAM_DETAIL_PAGE.CONFIRM_TEAM.TEAM_NAME}:</span>{" "}
              <span>{fplManager.current.entry_name}</span>
            </li>
            <li>
              <span>{TEAM_DETAIL_PAGE.CONFIRM_TEAM.MANAGER_NAME}:</span>{" "}
              <span>{fplManager.current.player_name}</span>
            </li>
            <li>
              <span>{TEAM_DETAIL_PAGE.CONFIRM_TEAM.TEAM_ID}:</span>{" "}
              <span>{fplManager.current.entry}</span>
            </li>
          </ul>
        ) : null}
      </ErrorModal>

      <Formik
        initialValues={initialValues as any}
        validationSchema={TeamDetailsYupSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form css={root(isSubmitting)}>
            <AutoCompleteInput<IFplManager>
              name="team_name"
              id="team_name"
              placeholder={TEAM_DETAIL_PAGE.TEAM_MANE_FIELD_PLACEHOLDER}
              onAutoCompleteChange={handleAutoCompleteChange}
              resultsArray={managers}
              onItemClick={handleItemClick}
              renderItem={(item) => (
                <Box css={autoCompleteRenderItemStyle}>
                  <Typography>{item.entry_name}</Typography>
                  <Typography>{item.player_name}</Typography>
                </Box>
              )}
            />

            <Typography css={teamIdSubtitleStyle}>
              {TEAM_DETAIL_PAGE.TEAM_ID_FIELD_SUBTITLE}
            </Typography>

            <Input
              Icon={<img src={userIcon} />}
              placeholder={TEAM_DETAIL_PAGE.TEAM_ID_FIELD_PLACEHOLDER}
              id="team_id"
              name="team_id"
            />

            <ErrorMessage name="generalErr" />
            <Button button="CTA" type="submit" disabled={isSubmitting}>
              {TEAM_DETAIL_PAGE.CTA_BUTTON_TEXT}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};
const teamIdSubtitleStyle = css`
  text-align: center;
`;

const modalButtonStyle = css`
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
`;

const autoCompleteRenderItemStyle = css`
  & > * {
    :first-of-type {
      font-weight: 700;
      font-size: 1.2rem;
    }
    :last-of-type {
      color: ${secondaryColor};
      font-weight: 700;
      font-size: 0.75rem;
    }
  }
`;

const fplManagerDetailsStyles = () => css`
  width: 100%;
  li {
    font-size: 0.875rem;
    display: flex;
    gap: 0.5rem;
    span:first-of-type {
      color: ${secondaryColor};
      font-weight: 600;
      width: 49%;
      text-align: left;
      text-align: right;
    }
  }
`;
